import React, { useEffect, useState } from "react";
// import Swiper core and required modules
import SwiperCore, { Autoplay, FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowResize from "../../hooks/useWindowResize";
import DonutChart from "../DonutChart/DonutChart";
import ArrowRight from "../../public/assets/icons/arrowRight.svg";
import styles from "./HomeMainCarousel.module.scss";

// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs, Autoplay]);

const HomeMainCarousel = ({ title, text, next, image, image_mobile, link }) => {
  const [swiper, setSwiper] = useState(null);
  const [listOfPictures, setListOfPicture] = useState(image);

  const isBelowMedium = useWindowResize(768);
  const isBelowSmall = useWindowResize(568);

  const [slideSelected, setSlideSelected] = useState(0);
  const [fadeInfo, setFadeInfo] = useState(false);

  useEffect(() => {
    if (isBelowSmall) {
      setListOfPicture(image_mobile);
    } else {
      setListOfPicture(image);
    }
  }, [isBelowSmall]);

  useEffect(() => {
    if(swiper == null) return;
    if (isBelowSmall) {
      let initialSlide = 3;
      if(listOfPictures.length < 3)
        initialSlide = listOfPictures.length;
      swiper.slideTo(initialSlide);
    }
  }, [swiper]);

  const handleActiveIndexChange = (e) => {
    let realIndex = 0;
    if (isBelowMedium) {
      /*if (e.activeIndex === listOfPictures.length * 2) {
        realIndex = 0;
      } else if (e.activeIndex === listOfPictures.length - 1) {
        realIndex = listOfPictures.length - 1;
      } else {
        realIndex = e.activeIndex - listOfPictures.length;
      }*/
      realIndex = e.realIndex;
    } else {
      realIndex = e.activeIndex;
      if (e.activeIndex === listOfPictures.length) {
        realIndex = 0;
      } else if (e.activeIndex === listOfPictures.length + 1) {
        realIndex = 1;
      }
    }
    setSlideSelected(realIndex);
  };
  return (
    <div className={`${styles.HomeMainCarousel} homeCarousel`}>
      {listOfPictures.map((d, i) => (
        <div key={i} className={styles.thumbContainer} style={{ position: "relative" }}>
          <div
            className={styles.thumbIndexContainer}
            style={{ display: slideSelected == i ? null : "none" }}
          >
            <DonutChart
              detectSlideChange={slideSelected}
              valueLabel={
                i > 0
                  ? `${i} / ${listOfPictures.length}`
                  : `${listOfPictures.length} / ${listOfPictures.length}`
              }
              strokeWidth={1}
              size={85}
            />
          </div>
          <div
            className={
              styles.thumbInfoContainer +
              " " +
              (fadeInfo ? styles.disappear : null)
            }
          >
            <div className="text-gold font-size-1">{next.toUpperCase()}</div>
            {text[slideSelected] && <div className={styles.nextText}>{text[slideSelected]}</div>}
          </div>
          <a
            className={
              styles.thumbPicture +
              " " +
              (slideSelected === i ? styles.selected : "")
            }
            href={link[slideSelected]?.url}
            target={link[slideSelected]?.target}
          >
            <img key={i} src={d.src} alt={d.alt} />
          </a>
        </div>
      ))}
      <Swiper
        loop={true}
        slidesPerView={isBelowMedium ? 3 : 1}
        className={styles.carouselSwiper}
        navigation
        spaceBetween={isBelowMedium ? 20 : 50}
        onActiveIndexChange={handleActiveIndexChange}
        onSlideChangeTransitionStart={() => setFadeInfo(false)}
        onSlideNextTransitionEnd={() => setFadeInfo(true)}
        onSlidePrevTransitionEnd={() => setFadeInfo(true)}
        onSwiper={setSwiper}
        centeredSlides={true}
        initialSlide={0}
        speed={800}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
      >
        {listOfPictures.map((d, i) => (
          <div key={i}>
            <SwiperSlide key={i}>
              <div
                className={`${styles.carouselSlideContent} ${isBelowMedium ? "carouselPic" : null
                  }`}
              >
                <a href={link[i]?.url} target="_self" className={styles.currentImageContainer}>
                  <img src={d.src} alt={d.alt} />
                </a>
                <div
                  className={
                    styles.carouselSlideInfo +
                    " " +
                    (fadeInfo ? styles.disappear : null)
                  }
                  style={{ display: isBelowMedium ? "none" : null }}
                >
                  <div className={styles.infoType}>
                    {title[i] && title[i].toUpperCase()}
                  </div>
                  <div className={styles.infoDesc}>{text[i]}</div>
                  {link && link.length > 0 && link[i] && (
                    <div className={styles.infoLink}>
                      {/* <SimpleLink content={link[i].title} url={link[i].url} /> */}
                      <a href={link[i].url} target="_self">
                        <div className={styles.linkContent}>
                          {link[i].title &&
                            <>
                              <div>{link[i].title}</div>

                              <div>
                                
                              </div>
                            </>
                          }
                        </div>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
      {link && link[slideSelected] &&
        <div
          className={styles.carouselSlideInfo}

        >
          {text[slideSelected] &&
            <div className={styles.infoDesc}>{text[slideSelected]}</div>
          }
          <div className={styles.infoLink}>
            <a href={link[slideSelected].url} target="_self">
              <div className={styles.linkContent}>
                {link[slideSelected].title &&
                  <>
                    <div>{link[slideSelected].title}</div>
                    <div>
                      <ArrowRight style={{ display: "block" }} />
                    </div>
                  </>
                }
              </div>
            </a>
          </div>
        </div>
      }
    </div>
  );
};

HomeMainCarousel.propTypes = {};

HomeMainCarousel.defaultProps = {};

export default HomeMainCarousel;
