import React, { useEffect, useState } from "react";
import useWindowResize from "../../hooks/useWindowResize";
import LinkIcon from "../LinkIcon/LinkIcon";
import SimpleLink from "../SimpleLink/SimpleLink";
import styles from "./CarouselCard.module.scss";

const CarouselCard = ({
  image,
  name,
  intro,
  hoverText,
  link,
  smallerCards = false,
}) => {
  const [cardImgBackground, setCardImgBackground] = useState("");
  const [text, setText] = useState();
  const [mouseEnter, setMouseEnter] = useState(false);
  const isBelowMedium = useWindowResize(768);

  const handleMouse = () => {
    if (!isBelowMedium) {
      setMouseEnter(!mouseEnter);
    }
  };

  useEffect(() => {
    if (image) {
      setCardImgBackground(image.src);
    }
    if (name) {
      let arr = name.split(" ");
      let len = 8;
      if (isBelowMedium) {
        len = 5;
      }
      if (arr.length > len) {
        setText(arr.slice(0, len).join(" ") + "...");
      } else {
        setText(name);
      }
    }
  }, [cardImgBackground, name, isBelowMedium]);

  return (
    <a href={link.url} >

      <div
        onMouseEnter={handleMouse}
        onMouseLeave={handleMouse}
        className={`${styles.CarouselCard} ${smallerCards && styles.smallerCarouselCard
          }`}
      >
        <div
          className={styles.cardImg}
          style={{
            backgroundImage: !mouseEnter
              ? `url(${cardImgBackground})`
              : `linear-gradient(0deg, rgba(23, 24, 28, 0.85), rgba(23, 24, 28, 0.75)), url(${cardImgBackground})`,
            height: smallerCards && "269.39px",
          }}
        >
          {link && (
            <a
              className={styles.cardImgLink}
              href={link.url}
              target={link.target}
            >
              <div
                className={`${styles.cardImgContent} ${styles.cardImgDiscover}`}
              >
                {hoverText}
              </div>
              
            </a>
          )}
        </div>
        <div
          className={styles.cardTitle}
          style={{ marginTop: smallerCards && "2.25rem" }}
        >
          {intro && <div className={styles.intro}>{intro}</div>}
          {text && <div>{text}</div>}
        </div>
        {link && (
          <div className={styles.cardMobileArrow}>
            <SimpleLink url={link.url} />
          </div>
        )}
      </div>
    </a>
  );
};

export default CarouselCard;
