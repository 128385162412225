import Link from 'next/link';
import React from 'react';
import ArrowRight from '../../public/assets/icons/arrowRight.svg';
import styles from './SimpleLink.module.scss';

const SimpleLink = ({ url, content }) => (
  <div className={styles.SimpleLink}>
    <a href={url} target="_self">
      <div className={styles.linkContent}>
        {content && (<div>{content}</div>)}
        <div>
          <ArrowRight style={{display:"block"}} />
        </div>
      </div>
    </a>
  </div>
);

SimpleLink.propTypes = {};

SimpleLink.defaultProps = {};

export default SimpleLink;
