import React, { useRef } from "react";
import AboutTheMall from "../AboutTheMall/AboutTheMall";
import CardCarousel from "../CardCarousel/CardCarousel";
import ExperienceCarousel from "../ExperienceCarousel/ExperienceCarousel";
import HomeMainCarousel from "../HomeMainCarousel/HomeMainCarousel";
import MailOrderSection from "../MailOrderSection/MailOrderSection";
import OurRestaurants from "../OurRestaurants/OurRestaurants";
import ReachTheMallHome from "../ReachTheMallHome/ReachTheMallHome";
import Map from "../Map/Map";
import styles from "./HomeContent.module.scss";
import ScrollDown from "../../components/ScrollDown/ScrollDown";
import Fade from "react-reveal/Fade";

const HomeContent = ({
  head,
  carousel,
  mailOrder,
  about,
  map,
  reach,
  experienceCarousel,
  restaurants,
}) => {
  const scrollToRef = useRef(null)
  return (
    <div className={styles.HomeContent} ref={scrollToRef}>
      {/* <Fade bottom> */}
      <div className={styles.headCarousel}>
        <HomeMainCarousel
          title={head.title}
          text={head.text}
          next={head.next}
          image={head.image}
          image_mobile={head.image_mobile}
          link={head.link}
        />
      </div>
      <div className={styles.cardCarousel}>
        <CardCarousel
          listOfBrands={carousel.items}
          hoverText={carousel.hoverText}
          goldTitle={carousel.goldTitle}
          more={carousel.more}
          lightGold
        />
      </div>
      <div className={"centeredSection " + styles.mailOrder} id="mailorder">
        <MailOrderSection title={mailOrder.title} link={mailOrder.link} />
      </div>
      <div className={"centeredSection " + styles.aboutTheMall} id="about">
        <AboutTheMall
          title={about.title}
          subtitle={about.subtitle}
          text={about.text}
          video={about.video}
          image={about.image}
          read={about.more}
        />
      </div>
      <div className={styles.fakeMap}>
        <Map title={map.title} url={map.url} />
      </div>
      <div className={"centeredSection " + styles.reachTheMall} id="reach">
        <ReachTheMallHome
          leftContent={reach.card.leftContent}
          rightContent={reach.card.rightContent}
        />
      </div>
      <div className={styles.expCarousel}>
        <ExperienceCarousel
          title={experienceCarousel.title}
          titles={experienceCarousel.titles}
          subtitle={experienceCarousel.subtitle}
          link={experienceCarousel.link}
          image={experienceCarousel.image}
          image_mobile={experienceCarousel.image_mobile}
          links={experienceCarousel.links}
        />
      </div>
      {false && <div className={"centeredSection " + styles.ourRestaurants} id="restaurants">
        <OurRestaurants
          title={restaurants.title}
          card={restaurants.card}
          booking={restaurants.booking}
          imageRight={restaurants.image_right}
          imageLeft={restaurants.image_left}
        />
      </div>}
      <div className="scroll">
        <ScrollDown selector={scrollToRef} scrollTop />
      </div>
      {/* </Fade> */}
    </div>
  );
};

export default HomeContent;
