import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './DonutChart.module.scss';

const DonutChart = ({ detectSlideChange, value = 0, valueLabel = 'Completed', size = 116, strokeWidth = 26, showValue = false }) => {
  const halfsize = (size * 0.5);
  const radius = halfsize - (strokeWidth * 3);
  const circumference = 2 * Math.PI * radius;
  const strokeval = ((value * circumference) / 100);
  const dashval = (strokeval + ' ' + circumference);

  const trackstyle = { strokeWidth: strokeWidth };
  const indicatorstyle = { strokeWidth: strokeWidth, strokeDasharray: dashval }
  const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';

  const [animated, setAnimated] = useState(false)

  useEffect(() => {
    if (value === 0 && detectSlideChange > 1 && !animated) {
      setAnimated(true)
    }
  }, [detectSlideChange])

  return (
    <div className={styles.DonutChart}>
      <svg width={size} height={size} className={styles.donutchart}>
        <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className={styles.donutchartTrack} />
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={indicatorstyle}
          className={styles.donutchartIndicator + ' ' + (animated ? styles.animated : null)}
        />
        <text className={styles.donutchartText} x={halfsize} y={halfsize} style={{ textAnchor: 'middle' }} >
          {showValue ? (
            <>
              <tspan className={styles.donutchartTextVal}>{value}</tspan>
              <tspan className={styles.donutchartTextPercent}>%</tspan>
            </>
          ) : null}
          <tspan
            className={showValue ? styles.donutchartTextLabel : `${styles.donutchartTextLabel} ${styles.onlyLabel}`}
            x={halfsize}
            y={showValue ? halfsize + 10 : halfsize + 5}
          >
            {valueLabel}
          </tspan>
        </text>
      </svg>
    </div>
  );
}




DonutChart.propTypes = {};

DonutChart.defaultProps = {};

export default DonutChart;
