import React from "react";
import styles from "./ReachTheMallHome.module.scss";
import OutletCard from "../OutletCard/OutletCard";
import ArrowRight from "../../public/assets/icons/arrowRight.svg";
import Fade from "react-reveal/Fade";

const ReachTheMallHome = ({ leftContent, rightContent }) => (
  <div className={styles.ReachTheMallHome + " block "}>
    <Fade bottom duration={2000} delay={100}>
    <div className={styles.theMallInfo}>
      <OutletCard
        title={leftContent.title}
        location={leftContent.location}
        address={leftContent.address}
        opening={leftContent.opening}
        closed={leftContent.closed}
        direction={leftContent.direction}
        links={leftContent.links}
        image={leftContent.image}
      />
    </div>
    </Fade>
    <Fade bottom duration={1000} delay={700} >
      <div className={styles.howToReach}>
        <div className={styles.howToReachTitleContainer}>
          <div className={styles.title + " font-size-2 "}>
            {rightContent.title}
          </div>
          <div>{rightContent.subtitle}</div>
        </div>
        <div className={styles.howToReachLinkContainer}>
          {rightContent.links.map((d) => (
            <a
              className={styles.howToReachLink}
              key={d.title}
              href={d.url.replace("#", "?q=")}
              target={d.target}
            >
              <div className={styles.linkTitle}>{d.title}</div>
              <ArrowRight />
            </a>
          ))}
        </div>
        <div className={styles.howToReachImg}>
          <img src={rightContent.image.src} alt={rightContent.image.alt} />
        </div>
      </div>
    </Fade>
  </div>
);

export default ReachTheMallHome;
