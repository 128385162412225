import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWindowResize from "../../hooks/useWindowResize";
import "react";
import VerticalScrollArrow from '../../public/assets/icons/verticalScrollArrow.svg';
import styles from "./ScrollDown.module.scss";

const ScrollDown = ({ selector, scrollTop = false }) => {
  const isMobile = useWindowResize(768);

  const scrollToBottom = () => {
    if (selector) {
      const offset = selector.current.offsetTop;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <button
      onClick={scrollToBottom}
      className={scrollTop ? styles.ScrollUp : styles.ScrollDown}
      style={{
        display:
          (isMobile && !scrollTop) || (!isMobile && scrollTop) ? "none" : null,
      }}
    >
      {scrollTop ? (
        <VerticalScrollArrow className={styles.arrowUp} />
      ) : (
        <VerticalScrollArrow className={styles.arrowDown} />
      )}
    </button>
  );
};

export default ScrollDown;