import styles from "./MailOrderSection.module.scss";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import mailOrderIcon from "../../public/assets/icons/mailOrderDark.svg";
import Reveal from "react-reveal/Fade";
// import { animationClasses } from '../../utils';

const MailOrderSection = ({ title, link }) => {
  return (
    <div className={`${styles.mailOrderSection} font-size-2`}>
      <Reveal bottom  effect="fadeInUp" duration={2000} delay={100}>
        <div className=" quote ">{title}</div>
        <div className={styles.button}>
          <ButtonWithIcon
            link={link}
            showArrow={false}
            outline={false}
          />
        </div>
      </Reveal>
    </div>
  );
};

export default MailOrderSection;
