import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import SwiperCore, { Autoplay, FreeMode, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowResize from "../../hooks/useWindowResize";
import CarouselCard from "../CarouselCard/CarouselCard";
import styles from "./CardCarousel.module.scss";
import Fade from "react-reveal/Fade";


SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const CardCarousel = ({
  listOfBrands,
  loop = true,
  hoverText,
  goldTitle,
  darkTitle,
  more,
  subtitle = null,
  lightGold = false,
  autoplay = true,
}) => {
  const [list, setList] = useState([]);
  const isBelowXL = useWindowResize(1200);
  const [loopResponsive, setLoopResponsive] = useState(loop);
  // const [mainSw, setMainSw] = useState(null);
  const [centered, setCentered] = useState(false);

  useEffect(() => {
    if (listOfBrands && listOfBrands.length > 0) {
      const itemsToDisplay = [];
      itemsToDisplay = listOfBrands.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
      setList(itemsToDisplay);
    }
  }, [listOfBrands]);

  useEffect(() => {
    if (isBelowXL) {
      setLoopResponsive(true);
    } else {
      setLoopResponsive(loop);
    }
  }, [isBelowXL]);

  // useEffect(() => {
  //   if (mainSw) {
  //     const { width, wrapperEl } = mainSw;
  //     const wrapperWidth = wrapperEl?.getBoundingClientRect()?.width;

  //     // check se le slides sono poche per il drag
  //     const overcome = wrapperWidth > width;

  //     if (!overcome) {
  //       mainSw.allowTouchMove = false;
  //       setPad(false)
  //     }
  //   }
  // }, [mainSw]);

  useEffect(() => {
    if (listOfBrands && listOfBrands.length < 5) {
      setCentered(true);
    }
  }, [listOfBrands]);

  return (
    <div style={{ overflow: "hidden" }}>
      <Fade right >
        <div className={styles.BrandCarousel}>
          <div
            className={darkTitle ? styles.title : styles.cardIntroText}
            style={{
              marginBottom: subtitle ? "1rem" : null,
              color: lightGold ? "#b1a38a" : null,
            }}
          >
            {goldTitle ? goldTitle : darkTitle ? darkTitle : null}
          </div>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          <div
            className={`${"cardCarouselWithCards"} ${centered ? "swiperCenter" : null
              }`}
          >
            <Swiper
              autoplay={autoplay &&
              {
                delay: 1,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              modules={autoplay && [Autoplay, Pagination, Navigation]}

              speed={autoplay && 2000}

              slidesPerView="auto"
              spaceBetween={25}
              centeredSlides={false}
              centeredSlidesBounds
              centerInsufficientSlides
              freeMode

            >
              {list.map((d, i) => (
                <SwiperSlide key={i}>
                  <CarouselCard
                    key={i}
                    name={d.name}
                    image={d.image}
                    // brandLogo={d.brandLogo}
                    hoverText={hoverText}
                    link={d.link}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <a
            className={styles.allBrandLink}
            style={{ display: more ? "block" : "none" }}
          >
            {more}
            <FontAwesomeIcon icon={faArrowRight} className={styles.arrowLink} />
          </a>
        </div>
      </Fade>
    </div>
  );
};

export default CardCarousel;
