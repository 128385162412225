import React, { useEffect, useState } from "react";
import styles from "./OutletCard.module.scss";
import Location from "../../public/assets/icons/location.svg";
import LocationDark from "../../public/assets/icons/location_dark.svg";

const OutletCard = ({
  title,
  location,
  address,
  opening,
  closed,
  links,
  image,
}) => {
  const [maps, setMaps] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [dark, setDark] = useState(false);

  useEffect(() => {
    if (links && links.length > 0) {
      links.map((d) => {
        if (d.type === "google_maps") {
          setMaps(d);
        } else if (d.type === "email") {
          setEmail(d);
        } else if (d.type === "phone") {
          setPhone(d);
        }
      });
    }
  }, [links]);

  const onHover = () => {
    setDark(!dark)
  }

  return (
    <div className={`${styles.outletCard} ${styles.light}`}>
      <div className={styles.cardDesc}>
        <div className={styles.cardDescImgContainer}>
          <img src={image.src} alt={image.alt} />
        </div>
        <div className={styles.cardContent}>
          <div className={styles.cardDescInfo}>
            <span>{title}</span>
            <p className={`text-unna-italic ${styles.cardDescInfoLocation}`}>
              {location}
            </p>
            <p className={styles.cardDescInfoAddress}>{address}</p>
          </div>
          <div className={styles.cardDescHours}>
            <span className="text-medium-gold">{opening.title}</span>
            <p dangerouslySetInnerHTML={{__html: opening.text}}></p>
          </div>

          <div className={styles.cardDescClosingDays}>
            {closed.text &&
              <>
                <span>{closed.title}</span>
                <p dangerouslySetInnerHTML={{__html: closed.text}}></p>
              </>
            }
          </div>
        </div>
      </div>
      {maps && (
        <a
          className={`text-unna-italic  ${styles.cardMap}`}
          href={maps.url}
          target={maps.target}
          onMouseEnter={onHover}
          onMouseLeave={onHover}
        >
          
          {maps.title}
        </a>
      )}
      {email && phone && (
        <div className={styles.cardRefs}>
          <a
            className="text-unna-italic"
            href={email.url}
            target={email.target}
            style={{ borderRight: "1px solid #B1A38A" }}
          >
            {email.title}
          </a>
          <a
            className="text-unna-italic"
            href={phone.url}
            target={phone.target}
          >
            {phone.title}
          </a>
        </div>
      )}
    </div>
  );
};

export default OutletCard;
