import React, { useEffect, useRef, useState } from "react";
// import Swiper core and required modules
import SwiperCore, { FreeMode, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowResize from "../../hooks/useWindowResize";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import LinkIcon from "../LinkIcon/LinkIcon";
import styles from "./ExperienceCarousel.module.scss";

// install Swiper modules
SwiperCore.use([FreeMode, Navigation]);

const ExperienceCarousel = ({
  title,
  titles,
  subtitle,
  link,
  image,
  image_mobile,
  links,
}) => {
  const [listOfPictures, setListOfPicture] = useState(image);
  const [indexLeftOffset, setIndexLeftOffet] = useState(0);
  const [slideSelected, setSlideSelected] = useState(0);
  const [fadeInfo, setFadeInfo] = useState(false);
  const itemsRef = useRef([]);
  const swiperRef = useRef(null);
  const mobileSwiperRef = useRef(null);

  const isBelowLarge = useWindowResize(768);

  useEffect(() => {
    if (isBelowLarge) {
      setListOfPicture(image_mobile);
    } else {
      setListOfPicture(image);
    }
  }, [isBelowLarge]);

  const handleClickNavigationItem = (e, index) => {
    setIndexLeftOffet(e.target.offsetLeft);
    setSlideSelected(index);
    // Switch to swiper slide (aggiunta 4 per gestire loop)
    swiperRef.current.swiper.slideTo(index + titles.length);
  };

  const handleActiveIndexChange = (e) => {
    let realIndex = 0;
    if (e.activeIndex === titles.length * 2) {
      // loop sulla prima
      realIndex = 0;
    } else if (e.activeIndex === titles.length - 1) {
      // scorrimento carosello a ritroso
      realIndex = titles.length - 1;
    } else {
      // -4 per loop swiper
      realIndex = e.activeIndex - titles.length;
    }
    // Tra gli items navigation bar sotto carosello Get item corrispondente alla slide corrente
    const activeItem = itemsRef.current[realIndex];
    // Set index della navbar
    setIndexLeftOffet(activeItem?.offsetLeft);
    setSlideSelected(realIndex);

    if (isBelowLarge) {
      mobileSwiperRef.current.swiper.slideTo(e.activeIndex);
    }
  };

  return (
    <div className={`${styles.ExperienceCarousel} experienceCarousel`}>
      <div className={styles.title}>{title.toUpperCase()}</div>
      <div>
        <Swiper
          className="afterDark"
          ref={swiperRef}
          slidesPerView={"auto"}
          onActiveIndexChange={handleActiveIndexChange}
          loop
          centeredSlides
          navigation
          loopedSlides={titles.length}
          onSlideChangeTransitionStart={() => setFadeInfo(false)}
          onSlideNextTransitionEnd={() => setFadeInfo(true)}
          onSlidePrevTransitionEnd={() => setFadeInfo(true)}
          activeSlideKey = '0'
        >
          {listOfPictures.map((d, i) => (
            <SwiperSlide key={i}>
              <div className={styles.carouselSlideContent}>
                <div>
                  <img src={d.src} alt={d.alt} />
                  <div
                    className={
                      styles.slideName +
                      " " +
                      (fadeInfo ? styles.disappear : null)
                    }
                    style={{ display: slideSelected != i ? "none" : null }}
                  >
                    {titles[i]}
                    <div className={styles.subtitle}>{subtitle[i]}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {isBelowLarge ? (
          <div>
            <Swiper
              className="experienceMobile"
              ref={mobileSwiperRef}
              slidesPerView={"auto"}
              centeredSlides
              onActiveIndexChange={(e) =>
                swiperRef.current.swiper.slideTo(e.activeIndex)
              }
              loop
              loopedSlides={titles.length}
            >
              {titles.map((d, i) => (
                <SwiperSlide key={i}>
                  <div className={styles.carouselSlideContent}>
                    <div key={i} className={styles.slideExperienceInfo} >
                      <a href={links[i].url.replace("#", "?q=")}>{d}</a>
                      
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : null}

        <div className={styles.experienceInfo}>
          <div className={styles.experienceNav}>
            <div className={styles.nav}>
              {subtitle.map((d, i) => (
                <a
                  key={i}
                  className={
                    styles.item +
                    " " +
                    (slideSelected === i ? styles.selected : "")
                  }
                  href={links[i].url.replace("#", "?q=")}
                >
                  <div
                    onMouseEnter={(e) => handleClickNavigationItem(e, i)}
                    ref={(ref) => (itemsRef.current[i] = ref)}
                  >
                    <div>{titles[i]}</div>
                  </div>
                  
                </a>
              ))}
              <div
                className={styles.experienceIndex}
                style={{ left: indexLeftOffset }}
              ></div>
            </div>
          </div>
          <div className={styles.experienceButton}>
            <ButtonWithIcon link={link} style={{ height: "37px" }} lightGold />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCarousel;
