import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import useWindowResize from "../../hooks/useWindowResize";
import PlayIcon from "../PlayIcon/PlayIcon";
import Fade from "react-reveal/Fade";
import styles from "./AboutTheMall.module.scss";

const AboutTheMall = ({ title, subtitle, text, video, image, read }) => {
  const [readmore, setReadMore] = useState(false);
  const isBelowMedium = useWindowResize(768);
  const [preview, setPreview] = useState();
  const [more, setMore] = useState();
  const ref = useRef();
  const stagger = 1000;

  const handleClick = () => {
    setReadMore(!readmore);
  };

  const textSlice = () => {
    const textArray = text.split(" ");
    setPreview(textArray.slice(0, 27).join(" "));
    setMore(textArray.slice(27).join(" "));
  };

  useEffect(() => {
    if (isBelowMedium) {
      setReadMore(false);
    } else {
      setReadMore(true);
    }
  }, [isBelowMedium]);

  useEffect(() => {
    {
      text && textSlice();
    }
  }, [text]);

  return (
    <div className={styles.AboutTheMall + " block "}>
      <Fade bottom cascade duration={1500} delay={500}>
        <div className={styles.FadeContainer} ref={ref}>
          <div className={styles.aboutInfo}>
            <div className={styles.aboutInfoSubtitle}>{title}</div>
            <div className={styles.aboutInfoTitle + " quote "}>{subtitle}</div>
            <div className={styles.aboutInfoDesc} dangerouslySetInnerHTML={{ __html: preview }}></div>
            {console.log(preview)}
            <div
              className={`${styles.aboutInfoDesc} ${
                readmore ? null : styles.aboutInfoDescClosed
              }`}
            >
              {more}
            </div>
            <div className={styles.readMore} onClick={handleClick}>
              {read}
              <button className={styles.accordionButton}>
                <div className={readmore ? styles.hideLineFirst : null}></div>
                <div className={readmore ? styles.hideLine : null}></div>
              </button>
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom duration={1300} delay={1000}>
        {video ? (
          <div className={styles.aboutVideo}>
            <ReactPlayer
              className={styles.player}
              url={video}
              muted={true}
              playing={true}
              playIcon={<PlayIcon />}
              light={image.src}
              controls
            />
          </div>
        ) : image ? (
          <img
            className={styles.aboutVideo}
            src={image.src}
            alt={image.alt}
          ></img>
        ) : null}
      </Fade>
    </div>
  );
};

export default AboutTheMall;
