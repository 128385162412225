
import HomeContent from '../../components/HomeContent/HomeContent'
import { fetchData } from '../../fetcher'
import TheMallOutletLayout from '../../layouts/TheMallOutletLayout/TheMallOutletLayout'
import AnimationsHelper from '../animations'
import React, {
  useEffect,
  useLayoutEffect
} from "react";

const OutletPageContainer = ({ menuItems, dataContent }) => {
  const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
  useIsomorphicLayoutEffect(() => {
    new AnimationsHelper().firstLoad();
  }, []);
  return (
    <TheMallOutletLayout menuItems={menuItems}>
      <HomeContent {...dataContent} />
    </TheMallOutletLayout>
  )
}

OutletPageContainer.getInitialProps = async (context) => {
  let menuItems = null;
  let dataContent = null;
  const { slug } = context.query
  const resmenu = await fetchData("/init", context.query.locale)
  const data = await fetchData(slug, context.query.locale);

  if (resmenu) {
    menuItems = resmenu.menu;
  }

  if (data) {
    dataContent = data;
  }

  return {
    menuItems: menuItems,
    dataContent: dataContent
  }
}

export default OutletPageContainer;
