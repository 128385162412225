import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './PlayIcon.module.scss'

const PlayIcon = () => {
    return (
        <div className={styles.playIcon}>
            <FontAwesomeIcon icon={faPlay} />
        </div>
    );
}

export default PlayIcon;