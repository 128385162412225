import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styles from './Map.module.scss';

const Map = ({ title, url }) => {
  const [srcMap, setSrcMap] = useState('')

  useEffect(() => {
    let isMounted = true;
    const fetchMap = async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: `${process.env.NEXT_PUBLIC_FE_MAPP}`,
          responseType: 'blob'
        })

        if (isMounted) {
          setSrcMap(URL.createObjectURL(res.data));
        }
      } catch (error) {
        console.error('Error loading map: ' + error);
      }
    }
    fetchMap()

    return () => {
      isMounted = false;
    }
  }, [])

  return (
    <div className={styles.Map}>
      <div className={styles.title}>{title}</div>
      {/* <iframe src={srcMap} height="500px" width="100%"></iframe> */}
      <iframe src={url} height="400px" width="100%" border="0" frameBorder='0'></iframe>
    </div>
  );
}
// 

Map.propTypes = {};

Map.defaultProps = {};

export default Map;
